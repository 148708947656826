<template>
  <div class="min-h-view overflow-auto flex flex-col">
    <div class="self-center flex flex-row flex-wrap justify-center items-center gap-8 flex-grow p-8">
      <img
        v-for="seminar in seminars"
        :key="seminar.id"
        :src="seminar.image"
        :alt="getSeminarAlt(seminar)"
        class="w-64 xl:w-84 cursor-pointer hover:scale-105"
        @click="openSeminar(seminar)"
      />
    </div>

    <Dialog
      v-if="selectedSeminar"
      @close="selectedSeminar = null"
      class="grid sm:grid-cols-2 w-3/4 max-w-3/4 max-h-3/4 sm:aspect-video max-sm:overflow-auto relative"
    >
      <img
        :src="selectedSeminar.image"
        :alt="getSeminarAlt(selectedSeminar)"
        class="hidden sm:block h-full object-cover"
      />
      <button
        @click="selectedSeminar = null"
        class="absolute top-2 right-2 w-8 h-8 rounded-full flex items-center justify-center text-center bg-black opacity-10 hover:opacity-20 text-white hover:text-opacity-50 text-xl"
      >
        &times;
      </button>
      <div class="flex flex-col gap-4 text-center bg-ul-orange-400 sm:bg-white max-h-full sm:overflow-auto">
        <div class="p-4 w-full">
          <h2 class="sm:hidden text-4xl text-ul-orange-400 bg-ul-orange-100 p-4">
            {{ selectedSeminar.speaker }}
          </h2>
          <h1 class="sm:hidden text-4xl text-ul-orange-100 p-4 mb-4">{{ selectedSeminar.title }}</h1>
          <p class="p-4 w-full text-left bg-ul-orange-100 sm:bg-white text-black whitespace-pre-line">
            {{ selectedSeminar.description }}
          </p>
        </div>
      </div>
    </Dialog>

    <PageFooter />
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue'

import Dialog from '../components/Dialog.vue'
import PageFooter from '../components/PageFooter.vue'
import { useSeminarStore } from '../stores/seminars'
import { Seminar } from '../types'

const seminarStore = useSeminarStore()
const seminars = seminarStore.seminars.toSorted((a, b) =>
  a.title.toLocaleLowerCase() > b.title.toLocaleLowerCase() ? 1 : -1
)

function getSeminarAlt(seminar: Seminar) {
  return `${seminar.speaker} - ${seminar.title}`
}

const selectedSeminar = ref<Seminar | null>(null)

function openSeminar(seminar: Seminar) {
  selectedSeminar.value = seminar
}
</script>

<style lang="scss"></style>
